import React from "react";

import "../../scss/molecules/_teaser-group.scss";
import "../../scss/molecules/_teaser.scss";

import sauceImg from "../../../static/design/img/2020/7045-300w.jpg";
import pastaImg from "../../../static/design/img/2020/0045-300w.jpg";
import ingredientImg from "../../../static/design/img/2020/6931-300w.jpg";
import platesImg from "../../../static/design/img/2020/plates.jpg";
//import ritornoLogo from "../../../static/design/img/ritorno.svg";

const TeaserGroup = () => (
  <section
    className="teaser-group pt-2 pt-md-0 pb-2 pattern-bg"
    style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
  >
    <div className="container">
      <div className="row eh-grid">
        <div className="col-md-3 col-sm-6 mb-1 mb-sm-2 mb-md-0">
          <div className="teaser">
            <div className="teaser-media">
              <img
                src={pastaImg}
                alt="Overheerlijke pasta's authentiek aan huis bereid"
                className="img-responsive"
              />
            </div>
            <div className="teaser-body">
              <h4 className="teaser-title">
                Rigatone, Fusili, Pappardelle,… overheerlijke pasta
              </h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-1 mb-sm-2 mb-md-0">
          <div className="teaser">
            <div className="teaser-media">
              <img
                src={sauceImg}
                alt="Onze verse sauzen"
                className="img-responsive"
              />
            </div>
            <div className="teaser-body">
              <h4 className="teaser-title">
                Bolognese, Curry,… verrukkelijke pastasauzen
              </h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-1 mb-sm-2 mb-md-0">
          <div className="teaser">
            <div className="teaser-media">
              <img
                src={ingredientImg}
                alt="Altijd verse ingrediënten van de beste kwaliteit"
                className="img-responsive"
              />
            </div>
            <div className="teaser-body">
              <h4 className="teaser-title">
                Verse sauzen en ingrediënten met pasta van topkwaliteit
              </h4>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 mb-1 mb-sm-2 mb-md-0">
          <div className="teaser">
            <div className="teaser-media">
              <img
                src={platesImg}
                alt="Wij voorzien alles, van pizza's tot bestek"
                className="img-responsive"
              />
            </div>
            <div className="teaser-body">
              <h4 className="teaser-title">
                Op de door u gekozen locatie, ruime keuze en 'à volonté'
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default TeaserGroup;
