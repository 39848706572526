import React from "react";

import Layout from "../components/organisms/layout";
import Footer from "../components/organisms/footer";
import Header from "../components/organisms/header";
import Contact from "../components/organisms/contact";
import TeaserGroup from "../components/organisms/teaser-group";

import Nav from "../components/molecules/nav";
import SEO from "../components/molecules/seo";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Mobiel pastakraam | Pastafeest | Pasta op locatie"
      description="Ritorno staat garant voor een evenement dat bijzonder origineel is
      en de nodige klasse naar de gasten toe uitstraalt. Wij garanderen u pastagerechten die de test met de gerechten uit
      het betere restaurant in Italië met glans doorstaan."
    />

    <Nav />
    <Header>
      <TeaserGroup />
    </Header>

    <section
      className="pt-8 pb-5 text-center zero-bottom pattern-bg"
      style={{ backgroundImage: "url(/design/img/pattern-background.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-8 mx-auto">
            <h2 className="title">
              Een trendy concept voor een onvergetelijke dag?
            </h2>
            <p>
              Wij garanderen u pastagerechten die de test met de gerechten uit
              het betere restaurant in Italië met glans doorstaan. Een en ander
              heeft te maken met het gebruik van uitsluitend versbereide
              producten van de hoogste kwaliteit. Wij beschikken over een
              uiterst gezellig kraam dat zowat op elke door u gekozen locatie
              kan geplaatst worden. Bovendien verzekeren wij u dat ons
              personeel, dat erg professioneel en zeer klantvriendelijk is, zich
              er volledig van bewust is dat hun inzet een wezenlijk onderdeel
              vormt van het doen slagen van uw event.
            </p>
          </div>
          <div className="col-sm-10 mx-auto">
            <img
              src="/design/img/center-block-background.png"
              className="screen"
              alt="Onze authentieke oven"
            />
          </div>
        </div>
      </div>
    </section>
    <Contact />

    <Footer />
  </Layout>
);

export default IndexPage;
